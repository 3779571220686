import React, { Component } from "react";
import styled from "styled-components";
import { ReactComponent as CapsButt } from "./images/caps-butt.svg";
import { ReactComponent as CapsSquare } from "./images/caps-square.svg";
import { ReactComponent as CapsRounded } from "./images/caps-rounded.svg";
import { ReactComponent as JoinsBevel } from "./images/joins-bevel.svg";
import { ReactComponent as JoinsRound } from "./images/joins-round.svg";
import { ReactComponent as JoinsSquare } from "./images/joins-square.svg";
import Canvas from "./Canvas";
import Code from "./Code";
import ActionBar from "./ActionBar";
import Range from "./Controls/Range";
import Color from "./Controls/Color";
import ColorGroup from "./Controls/ColorGroup";
import Segmented from "./Controls/Segmented";
import { generateSVGCode, generateJSXCode, generateReactCode } from "./helper";
import AppContext from "./AppContext.js";

const LineCapsOptions = [
  { name: "butt", icon: <CapsButt /> },
  { name: "round", icon: <CapsRounded /> },
  { name: "square", icon: <CapsSquare /> }
];

const LineJoinsOptions = [
  { name: "bevel", icon: <JoinsBevel /> },
  { name: "round", icon: <JoinsRound /> },
  { name: "arcs", icon: <JoinsSquare /> }
];

export default class Sidebar extends Component {
  state = {
    svg: "",
    jsx: "",
    react: "",
    setName: this.props.setName,
    code: this.props.icon ? this.props.icon.code : null,
    name: this.props.icon ? this.props.icon.name : "Icon",
    strokeColor: localStorage.getItem("strokeColor") || "#000000",
    strokeWidth: Number(localStorage.getItem("strokeWidth")) || 2.0,
    iconSize: Number(localStorage.getItem("iconSize")) || 24,
    linecap: localStorage.getItem("linecap") || "round",
    linejoin: localStorage.getItem("linejoin") || "round",
    currentCodeType: "SVG",
    viewBox: this.props.viewBox,
    type: this.props.type,
    fillColors: this.props.fillColors,
    cornerRadius: this.props.cornerRadius,
    useCurrentColor:
      JSON.parse(localStorage.getItem("useCurrentColor")) || false
  };

  componentDidMount = () => {
    this.updateSVG();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.icon !== this.props.icon) {
      if (this.state.setName !== this.props.setName) {
        this.setState(
          {
            code: this.props.icon.code,
            name: this.props.icon.name,
            viewBox: this.props.viewBox,
            setName: this.props.setName,
            cornerRadius: this.props.cornerRadius,
            fillColors: this.props.fillColors,
            type: this.props.type
          },
          this.updateSVG
        );
      } else {
        this.setState(
          {
            code: this.props.icon.code,
            name: this.props.icon.name,
            viewBox: this.props.viewBox
          },
          this.updateSVG
        );
      }
    }
  };

  // shouldComponentUpdate = (nextProps, nextState) => {
  //   if (this.state.code !== nextProps.icon.code) {
  //     return true;
  //   }
  //   if (this.state.svg !== nextState.svg) {
  //     return true;
  //   }
  //   if (this.state.currentCodeType !== nextState.currentCodeType) {
  //     return true;
  //   }
  //   return false;
  // };

  changeColor = strokeColor => {
    this.setState({ strokeColor }, this.updateSVG);
    console.log("strokeColor", strokeColor);
    localStorage.setItem("strokeColor", strokeColor);
  };

  changeWidth = strokeWidth => {
    this.setState({ strokeWidth }, this.updateSVG);
    localStorage.setItem("strokeWidth", strokeWidth);
  };

  changeSize = iconSize => {
    this.setState({ iconSize }, this.updateSVG);
    localStorage.setItem("iconSize", iconSize);
  };

  changeViewBoxSize = viewBoxSize => {
    this.setState({ viewBoxSize }, this.updateSVG);
    localStorage.setItem("viewBoxSize", viewBoxSize);
  };

  changeLinecap = linecap => {
    this.setState({ linecap }, this.updateSVG);
    localStorage.setItem("linecap", linecap);
  };

  changeLinejoin = linejoin => {
    this.setState({ linejoin }, this.updateSVG);
    localStorage.setItem("linejoin", linejoin);
  };

  changeCornerRadius = cornerRadius => {
    this.setState({ cornerRadius }, this.updateSVG);
  };

  setFillColors = fillColors => {
    let colors;
    if (fillColors.constructor === Array) {
      colors = fillColors;
    } else {
      colors = [fillColors];
    }
    this.setState({ fillColors: colors }, this.updateSVG);
  };

  setMultiFillColors = (newColor, index) => {
    this.setState(state => {
      const fillColors = state.fillColors.map((color, j) => {
        if (j === index) {
          return newColor;
        } else {
          return color;
        }
      });
      return {
        fillColors
      };
    }, this.updateSVG);
  };

  setCodeType = type => {
    this.setState({ currentCodeType: type });
  };

  toggleCurrentColor = () => {
    let bool = !this.state.useCurrentColor;
    this.setState({ useCurrentColor: bool }, this.updateSVG);
    localStorage.setItem("useCurrentColor", bool);
  };

  updateSVG = () => {
    let {
      code,
      name,
      strokeColor,
      strokeWidth,
      cornerRadius,
      iconSize,
      linecap,
      linejoin,
      useCurrentColor,
      viewBox,
      type,
      fillColors
    } = this.state;

    let properties = {
      name,
      strokeWidth,
      cornerRadius,
      strokeColor,
      useCurrentColor,
      iconSize,
      linecap,
      linejoin,
      viewBox,
      type,
      fillColors,
      code
    };
    // console.log("update svg");
    this.setState({
      svg: generateSVGCode(type, properties),
      jsx: generateJSXCode(type, properties),
      react: generateReactCode(type, properties)
    });
  };

  getCurrentCode = type => {
    let { svg, jsx, react } = this.state;
    if (type === "SVG") {
      return svg;
    } else if (type === "JSX") {
      return jsx;
    } else {
      return react;
    }
  };

  resetConfig = () => {
    console.log("type " + this.state.type);
    this.changeSize(this.props.viewBox);
    this.changeCornerRadius(this.props.cornerRadius);
    if (this.props.type === "outline") {
      this.changeWidth(2);
      this.changeLinecap("round");
      this.changeColor("#000000");
      this.changeLinejoin("round");
    }
    if (this.props.type === "fill") {
      this.setFillColors(this.props.fillColors);
    }
    if (this.props.useCurrentColor) {
      this.toggleCurrentColor();
    }
  };

  render() {
    let {
      svg,
      name,
      currentCodeType,
      strokeWidth,
      strokeColor,
      useCurrentColor,
      cornerRadius,
      iconSize,
      linecap,
      linejoin,
      type,
      fillColors,
      setName
    } = this.state;

    let currentCode = this.getCurrentCode(currentCodeType);

    return (
      <AppContext.Consumer>
        {({ darkMode }) => (
          <>
            <Wrap darkMode={darkMode}>
              {svg && (
                <>
                  <Scroll darkMode={darkMode}>
                    <div className="details">
                      <div className="titlebar">
                        <p>{name}.svg</p>
                        <a className="reset" onClick={() => this.resetConfig()}>
                          Reset
                        </a>
                      </div>
                      <Canvas svg={svg} />
                      <Config>
                        <Range
                          label="Icon size"
                          value={iconSize}
                          min={16}
                          max={100}
                          step={1}
                          onChange={this.changeSize}
                        />

                        {type === "outline" && (
                          <Range
                            label="Stroke width"
                            value={strokeWidth}
                            min={1.0}
                            max={4.0}
                            step={0.5}
                            onChange={this.changeWidth}
                          />
                        )}
                        {type === "fill" && cornerRadius !== null && (
                          <Range
                            label="Corner radius"
                            value={cornerRadius}
                            min={0}
                            max={50}
                            step={1}
                            onChange={this.changeCornerRadius}
                          />
                        )}
                        {type === "fill" && fillColors.length > 1 && (
                          <ColorGroup
                            label={"Fill colors"}
                            colors={fillColors}
                            setMultiFillColors={this.setMultiFillColors}
                          />
                        )}

                        {type === "fill" && fillColors.length === 1 && (
                          <Color
                            label="Fill color"
                            value={fillColors[0]}
                            changeColor={this.setFillColors}
                            hideCurrentColor={true}
                          />
                        )}

                        {type === "outline" && (
                          <Color
                            label="Stroke color"
                            value={strokeColor}
                            changeColor={this.changeColor}
                            toggleCurrentColor={this.toggleCurrentColor}
                            useCurrentColor={useCurrentColor}
                          />
                        )}
                        {type === "outline" && (
                          <Segmented
                            label="Ends"
                            value={linecap}
                            onChange={this.changeLinecap}
                            options={LineCapsOptions}
                          />
                        )}

                        {type === "outline" && (
                          <Segmented
                            label="Joins"
                            value={linejoin}
                            onChange={this.changeLinejoin}
                            options={LineJoinsOptions}
                          />
                        )}
                      </Config>
                      <Code
                        currentCodeType={currentCodeType}
                        setCodeType={this.setCodeType}
                        currentCode={currentCode}
                      />
                    </div>
                  </Scroll>
                  <ActionBar
                    svg={svg}
                    code={currentCode}
                    setName={setName}
                    type={currentCodeType}
                    name={name}
                  />
                </>
              )}
            </Wrap>
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

const Wrap = styled.div`
  width: 370px;
  height: 100vh;
  background: ${props => (props.darkMode ? "rgb(25, 26, 30)" : "#f7f7f7")};
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.05);
  position: relative;
  .details {
    padding: 24px 32px;
  }
`;

const Scroll = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 68px);

  .titlebar {
    margin: 0 0 20px;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 15px;
      font-weight: 500;
      color: ${props => (props.darkMode ? "rgba(255,255,255,0.8)" : "#555")};
    }
    a {
      color: #0094dd;
      cursor: pointer;
      font-weight: 500;
      font-size: 15px;
      display: inline-block;
    }
  }
`;

const Config = styled.div`
  margin-bottom: 20px;
`;
