import React, { Component } from "react";
import styled from "styled-components";
import AppContext from "./AppContext.js";
import { ReactComponent as SearchIcon } from "./images/search.svg";
import { ReactComponent as ClearIcon } from "./images/clear.svg";

export default class Header extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ searchText, onSearch, darkMode }) => (
          <Container darkMode={darkMode}>
            <Searchbar darkMode={darkMode}>
              <SearchIcon />
              <Input
                darkMode={darkMode}
                placeholder={`Search icons...`}
                value={searchText}
                onChange={e => onSearch(e.target.value)}
              />
              {searchText !== "" && (
                <button onClick={e => onSearch("")}>
                  <ClearIcon />
                </button>
              )}
            </Searchbar>
          </Container>
        )}
      </AppContext.Consumer>
    );
  }
}

const Container = styled.div`
  padding: 10px 0;
  margin: 0 20px;
  font-size: 15px;
  background: ${props => (props.darkMode ? "rgb(8, 8, 8)" : "#fff")};
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Searchbar = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  & > svg {
    position: absolute;
    left: 12px;
  }
  button {
    position: absolute;
    right: 15px;
    background: ${props => (props.darkMode ? "rgba(255,255,255,0.3)" : "#ccc")};
    border: 0;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    padding-top: 2px;
    outline: none;
    svg {
      stroke: ${props => (props.darkMode ? "#2A2C34" : "#f8f8f8")};
    }
  }
`;

const Input = styled.input`
  padding: 10px 10px 10px 40px;
  border: none;
  flex: 1;
  font-size: 16px;
  background-color: ${props => (props.darkMode ? "#2A2C34" : "#f2f2f2")};
  color: ${props => (props.darkMode ? "#eee" : "#333")};
  border-radius: 10px;
  left: 0;
  outline: none;
  &:focus {
    background-color: ${props => (props.darkMode ? "#2A2C34" : "#f8f8f8")};
    box-shadow: #99d5f2 0px 0px 0px 2px;
  }
`;
