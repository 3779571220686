import React, { Component } from "react";
import styled from "styled-components";

class Tool extends Component {
  state = {
    output: "",
  };

  onChange = (e) => {
    console.log(e.target.value);
    let svgcode = e.target.value;
    svgcode = svgcode
      .replace(/(fill="#FFF")/g, `fill="#FFF" class="two"`)
      .replace(/(fill="#00BA76")/g, `fill="#00BA76" class="one"`)
      .replace(/[\r\n]+/gm, "")
      .replace(/ +/g, " ") // Remove double spaces
      .trim();
    this.setState({ output: svgcode });
  };
  onChange2 = (e) => {
    console.log(e.target.value);
    let svgcode = e.target.value;
    svgcode = svgcode
      .replace(/(fill="#000")/g, "")
      .replace(/(fill-rule="nonzero")/g, "")
      .replace(/[\r\n]+/gm, "")
      .replace(/ +/g, " ") // Remove double spaces
      .trim();
    this.setState({ output: svgcode });
  };

  render() {
    let c =
      "{name: 'terminal',code:'" +
      this.state.output +
      "',tags: ['coding', 'developer', 'programmer']},";
    return (
      <Wrap>
        <h1>Tool</h1>
        <textarea onChange={(e) => this.onChange(e)} />
        <br />
        <br />
        <h3>Output</h3>
        <textarea value={this.state.output} />
        <h3>Code</h3>
        <textarea value={c} />
      </Wrap>
    );
  }
}

export default Tool;

const Wrap = styled.div`
  padding: 20px;
  textarea {
    width: 600px;
    height: 200px;
    font-size: 16px;
    padding: 20px;
  }
`;
