import React, { Component } from "react";
import styled from "styled-components";
import me from "./images/me.jpg";
import About from "./About";
import AppContext from "./AppContext.js";
import { ReactComponent as ToggleOff } from "./images/toggle-off.svg";
import { ReactComponent as ToggleOn } from "./images/toggle-on.svg";
import CarbonAds from "react-carbon-ads";

export default class Footer extends Component {
  state = {
    showAbout: false,
  };

  toggleAbout = () => {
    this.setState({
      showAbout: !this.state.showAbout,
    });
  };

  render() {
    return (
      <AppContext.Consumer>
        {({ darkMode, toggleDarkMode }) => (
          <>
            <Wrap darkMode={darkMode}>
              <CarbonAds carbonUrl="//cdn.carbonads.com/carbon.js?serve=CEBIT2QN&placement=iconsvgxyz" />

              <p className="darkmode">
                <span>Dark mode</span>
                <a onClick={() => toggleDarkMode()}>
                  {darkMode ? <ToggleOn /> : <ToggleOff />}
                </a>
              </p>
              <div className="listing">
                <a href="#" onClick={() => this.toggleAbout()}>
                  About ICONSVG
                </a>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.twitter.com/gaddafirusli"
                >
                  Made by @gaddafirusli
                </a>
              </div>
            </Wrap>
            {this.state.showAbout && <About toggleAbout={this.toggleAbout} />}
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

const Wrap = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0 14px 16px;
  width: 200px;
  p.darkmode {
    padding: 15px 0 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: ${(props) =>
      props.darkMode
        ? "1px solid rgba(255, 255, 255, 0.2);"
        : "1px solid rgba(0, 0, 0, 0.1)"};
    color: ${(props) =>
      props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.8)"};
    a {
      color: ${(props) =>
        props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.8)"};
      cursor: pointer;
      transform: scale(1.1);
      &:hover {
        color: #2692ee;
      }
    }
  }
  .listing {
    padding-top: 10px;
    border-top: ${(props) =>
      props.darkMode
        ? "1px solid rgba(255, 255, 255, 0.2);"
        : "1px solid rgba(0, 0, 0, 0.1)"};
    a {
      font-size: 15px;
      text-decoration: none;
      color: ${(props) =>
        props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.8)"};
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        color: #2692ee;
      }
      span {
        margin-right: 8px;
      }
    }
  }
  .carbon-adds-wrapper {
    margin: 0;
    text-align: left;
  }
  #carbonads {
    display: block;
    overflow: hidden;
    padding: 0 0 10px 0;
    background: transparent;
    text-align: center;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    line-height: 1.5;
    margin-bottom: 10px;
    box-shadow: none;
  }

  #carbonads a {
    color: ${(props) =>
      props.darkMode ? "rgba(255, 255, 255, 0.85);" : "rgba(0, 0, 0, 0.8)"};
    text-decoration: none;
  }

  #carbonads a:hover {
    color: ${(props) =>
      props.darkMode ? "rgba(255, 255, 255, 1);" : "rgba(0, 0, 0, 1)"};
  }

  #carbonads span {
    display: block;
    overflow: hidden;
  }

  #carbonads a.carbon-img {
    display: block;
    margin: 0 0 8px;
    line-height: 1;

    float: none;
    img {
      float: none;
      margin: 0;
      /* width: 100%; */
      /* height: auto; */
    }
  }

  .carbon-text {
    display: block;
    margin-bottom: 8px;
    padding: 0;
    text-align: left;
  }

  .carbon-poweredby {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    padding: 0;
    line-height: 1;
    text-align: left;
  }
`;
