import React from "react";
import styled from "styled-components";
import ClickToSelect from "@mapbox/react-click-to-select";
import Highlight from "react-highlight.js";
import AppContext from "./AppContext.js";

const Code = ({ currentCodeType, setCodeType, currentCode }) => {
  let type = ["SVG", "JSX", "React"];
  return (
    <AppContext.Consumer>
      {({ darkMode }) => (
        <>
          <Editor>
            <CodeTabs darkMode={darkMode}>
              {type.map((t, index) => {
                return (
                  <button
                    key={index}
                    className={currentCodeType === t ? "active" : ""}
                    onClick={() => setCodeType(t)}
                  >
                    {t}
                  </button>
                );
              })}
            </CodeTabs>
            <CodeBlock darkMode={darkMode}>
              <ClickToSelect>
                <Highlight
                  className={darkMode ? "dark" : "light"}
                  language={currentCodeType === "React" ? "javascript" : "xml"}
                >
                  {currentCode}
                </Highlight>
              </ClickToSelect>
            </CodeBlock>
          </Editor>
        </>
      )}
    </AppContext.Consumer>
  );
};

const Editor = styled.div`
  pre {
    color: black;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;
const CodeTabs = styled.div`
  display: flex;
  bottom: -1px;
  position: relative;
  button {
    border: 0;
    background: ${props => (props.darkMode ? "#2a2c34" : "transparent")};
    padding: 10px 28px 12px;
    border-right: ${props =>
      props.darkMode ? "2px solid rgb(25, 26, 30)" : "1px solid #ddd"};
    border-top: ${props =>
      props.darkMode ? "1px solid rgb(25, 26, 30)" : "1px solid #ddd"};
    font-size: 14px;
    font-weight: 500;
    outline: none;
    color: ${props => (props.darkMode ? "rgba(255,255,255,0.8)" : "#666")};
    flex: 1;
    cursor: pointer;
    &:first-child {
      border-left: ${props =>
        props.darkMode ? "1px solid rgb(25, 26, 30)" : "1px solid #ddd"};
      border-radius: 4px 0 0;
    }
    &:last-child {
      border-radius: 0 4px 0 0;
    }
    &.active {
      background: ${props => (props.darkMode ? "#101115" : "#fff")};
      color: #0094dd;
      border-bottom-color: #fff;
    }
  }
`;

const CodeBlock = styled.div`
  outline: 0;
  padding: 5px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 15px;
  background: ${props => (props.darkMode ? "#101115" : "#fff")};
  border-radius: 0 0 4px 4px;
  border: ${props =>
    props.darkMode ? "1px solid rgb(25, 26, 30)" : "1px solid #ddd"};
  .hljs {
    display: block;
    overflow-x: auto;
    padding: 8px 12px 12px;
  }
  pre.dark {
    .hljs {
      color: #f8f8f8;
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-type {
      color: #e28964;
    }

    .hljs-string {
      color: #65b042;
    }

    .hljs-subst {
      color: #daefa3;
    }

    .hljs-regexp,
    .hljs-link {
      color: #e9c062;
    }

    .hljs-title,
    .hljs-section,
    .hljs-tag,
    .hljs-name {
      color: #89bdff;
    }

    .hljs-class .hljs-title,
    .hljs-doctag {
      text-decoration: underline;
    }

    .hljs-symbol,
    .hljs-bullet,
    .hljs-number {
      color: #3387cc;
    }

    .hljs-params,
    .hljs-variable,
    .hljs-template-variable {
      color: #3e87e3;
    }

    .hljs-attribute {
      color: #cda869;
    }

    .hljs-meta {
      color: #8996a8;
    }

    .hljs-formula {
      background-color: #0e2231;
      color: #f8f8f8;
      font-style: italic;
    }

    .hljs-selector-class {
      color: #9b703f;
    }

    .hljs-selector-id {
      color: #8b98ab;
    }
  }
  pre.light {
    .hljs {
      color: black;
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-section,
    .hljs-title,
    .hljs-name {
      color: #008;
    }
    .hljs-variable,
    .hljs-template-variable {
      color: #660;
    }
    .hljs-string,
    .hljs-selector-attr,
    .hljs-selector-pseudo,
    .hljs-regexp {
      color: #080;
    }
    .hljs-literal,
    .hljs-symbol,
    .hljs-bullet,
    .hljs-meta,
    .hljs-number,
    .hljs-link {
      color: #066;
    }
    .hljs-title,
    .hljs-doctag,
    .hljs-type,
    .hljs-attr,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-params {
      color: #606;
    }
    .hljs-attribute,
    .hljs-subst {
      color: #000;
    }
    .hljs-formula {
      background-color: #eee;
      font-style: italic;
    }
    .hljs-selector-id,
    .hljs-selector-class {
      color: #9b703f;
    }
  }
`;

export default Code;
