import React, { Component } from "react";
import styled from "styled-components";
import IconItem from "./IconItem.js";
import AppContext from "./AppContext.js";

export default class Search extends Component {
  state = {
    searchText: this.props.searchText,
    allicons: [],
    results: []
  };

  componentDidMount = () => {
    this.combineIcons();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.searchText !== this.props.searchText) {
      this.setState(
        {
          searchText: this.props.searchText
        },
        this.getSearchResults
      );
    }
    if (prevProps.currentSet !== this.props.currentSet) {
      this.combineIcons();
    }
  };

  combineIcons = () => {
    // Combine all icons into one flat array
    let allicons = [];
    this.props.currentSet.content.map(el => {
      el.icons.map(ic => {
        allicons.push(ic);
      });
    });

    this.setState(
      {
        allicons
      },
      this.getSearchResults
    );
  };

  getSearchResults = () => {
    let searchText = this.state.searchText.toLowerCase().trim();
    let allicons = this.state.allicons;

    // Filter the array
    let results = allicons.filter(ic => {
      let tags = ic.tags.join(" ");
      let name = ic.name;
      return name.includes(searchText) || tags.includes(searchText);
    });

    this.setState({
      results
    });
  };

  render = () => {
    let { searchText, results } = this.state;
    let currentSet = this.props.currentSet;
    let noResults = results.length === 0;
    return (
      <AppContext.Consumer>
        {({ selectIcon, selected, darkMode }) => (
          <Section darkMode={darkMode}>
            {!noResults && (
              <Grid size={currentSet.size + 30 + 16}>
                {results.map((ic, index) => {
                  return (
                    <IconItem
                      key={index}
                      size={currentSet.size}
                      type={currentSet.type}
                      viewBox={currentSet.viewBox}
                      stroke={currentSet.type === "outline" ? 2 : 0}
                      code={currentSet.preCode + ic.code + currentSet.postCode}
                      name={ic.name}
                      active={selected ? selected.name === ic.name : false}
                      selectIcon={selectIcon}
                    />
                  );
                })}
              </Grid>
            )}
            {noResults && (
              <p>{`No icons found for "${searchText}" in ${currentSet.name}. Please try a different keyword.`}</p>
            )}
          </Section>
        )}
      </AppContext.Consumer>
    );
  };
}

Search.contextType = AppContext;

const Section = styled.div`
  margin: 0 auto 40px;
  max-width: 1000px;
  p {
    color: ${props =>
      props.darkMode ? "rgba(255, 255, 255, 0.5);" : "rgba(0, 0, 0, 0.6)"};
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: ${props =>
    props.size ? "repeat(auto-fill, " + props.size + "px)" : ""};
  justify-content: space-between;
`;
