import React, { Component } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Browser from "./Browser";
import Search from "./Search";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";
import AppContext from "./AppContext.js";
import ReactGA from "react-ga";
import feathers from "./data/feathers";
import PayWall from "./PayWall.js";

class App extends Component {
	state = {
		selected: JSON.parse(localStorage.getItem("selected")) || {
			code: `<path d="M12 19V6M5 12l7-7 7 7"/>`,
			name: "arrow-up",
		},
		currentSet: JSON.parse(localStorage.getItem("currentSet")) || feathers,
		searchText: "",
		darkMode: localStorage.getItem("darkModeTheme") === "true" ? true : false,
		ipc: JSON.parse(localStorage.getItem("ipc")) || [],
	};

	componentDidMount = () => {
		ReactGA.initialize("UA-134242174-1");
		ReactGA.pageview(window.location.pathname + window.location.search);
		// this.verifyLicense("2024654C-8864410D-99AB1E69-7AA4D9F3");
	};

	verifyLicense = (setId, productId, key) => {
		return new Promise((resolve, reject) => {
			fetch(
				"https://gumroad-verify-sand.vercel.app/verify?product=" +
					productId +
					"&key=" +
					key,
				{
					mode: "cors",
				}
			)
				.then((res) => res.json())
				.then((data) => {
					if (data.success && !data.purchase.refunded) {
						ReactGA.event({
							category: "License",
							action: "Success",
							label: `${productId} / ${key}`,
						});
						let ipc = this.state.ipc;
						ipc.push(setId);
						this.setState({ ipc: ipc });
						localStorage.setItem("ipc", JSON.stringify(ipc));
						resolve(true);
					} else {
						ReactGA.event({
							category: "License",
							action: "Failed",
							label: `${productId} / ${key}`,
						});
						resolve(false);
					}
				})
				.catch((error) => console.log(error));
			//TODO: Catch error
		});
	};

	setCurrentSet = (set) => {
		this.setState({ currentSet: set });
		ReactGA.event({
			category: "Views",
			action: "Current Set",
			label: `${set.id}`,
		});
	};

	toggleDarkMode = () => {
		this.setState({ darkMode: !this.state.darkMode });
		localStorage.setItem("darkModeTheme", !this.state.darkMode);
		ReactGA.event({
			category: "Clicks",
			action: "Dark Mode",
			label: `${!this.props.darkMode}`,
		});
	};

	selectIcon = (icon) => {
		this.setState({ selected: icon });
		localStorage.setItem("selected", JSON.stringify(icon));
		localStorage.setItem("currentSet", JSON.stringify(this.state.currentSet));
	};

	onSearch = (text) => {
		this.setState({
			searchText: text,
		});
	};

	render = () => {
		let isSearching = this.state.searchText.trim() !== "";
		let currentSet = this.state.currentSet;
		let ipc = this.state.ipc;
		let isEligible = ipc.indexOf(currentSet.id) > -1;
		return (
			<AppContext.Provider
				value={{
					searchText: this.state.searchText,
					onSearch: this.onSearch,
					selectIcon: this.selectIcon,
					selected: this.state.selected,
					currentSet: this.state.currentSet,
					darkMode: this.state.darkMode,
					toggleDarkMode: this.toggleDarkMode,
					setCurrentSet: this.setCurrentSet,
					verifyLicense: this.verifyLicense,
					ipc: this.state.ipc,
				}}
			>
				<>
					<Container>
						<Menu />
						<Wrap darkMode={this.state.darkMode}>
							{currentSet.isPremium && !isEligible && (
								<Content>
									<PayWall />
								</Content>
							)}
							{(!currentSet.isPremium || isEligible) && (
								<>
									<Header />
									<Content>
										{isSearching ? (
											<Search
												searchText={this.state.searchText}
												currentSet={this.state.currentSet}
											/>
										) : (
											<Browser />
										)}
									</Content>
								</>
							)}

							{/* <Footer /> */}
						</Wrap>
						{(!currentSet.isPremium || isEligible) && (
							<Sidebar
								setName={this.state.currentSet.name}
								icon={this.state.selected}
								type={this.state.currentSet.type}
								viewBox={this.state.currentSet.viewBox}
								fillColors={this.state.currentSet.fillColors}
								cornerRadius={this.state.currentSet.cornerRadius}
							/>
						)}
					</Container>
				</>
			</AppContext.Provider>
		);
	};
}

export default App;

const Container = styled.div`
	display: flex;
`;

const Content = styled.div`
	padding: 30px 40px;
`;
const Wrap = styled.div`
	flex: 1;
	height: 100vh;
	overflow-y: auto;
	background: ${(props) => (props.darkMode ? "rgb(0,0,0)" : "#fff")};
`;
