import React, { Component } from "react";
import styled from "styled-components";
import CoasterIcon from "./images/p-coaster.png";
import ShotsnappIcon from "./images/p-shotsnapp.png";
import OverframeIcon from "./images/p-overframe.png";
import { ReactComponent as Close } from "./images/close.svg";
import Transition from "react-transition-group/Transition";

export default class About extends Component {
  state = {
    isMounted: false
  };

  componentDidMount = () => {
    this.setState({ isMounted: true });
  };

  handleClose = () => {
    this.setState({
      isMounted: false
    });
  };

  onExited = () => {
    this.props.toggleAbout();
  };

  render() {
    const overlayStyle = {
      entering: {
        background: "rgba(0, 0, 0, 0)"
      },
      entered: { background: "rgba(0, 0, 0, 0.3)" },
      exiting: {
        background: "rgba(0, 0, 0, 0)"
      }
    };

    const popupStyle = {
      entering: {
        opacity: 0,
        transform: "scale(0.9)"
      },
      entered: {
        opacity: 1,
        transform: "scale(1)"
      },
      exiting: {
        opacity: 0,
        transform: "scale(0.9)"
      }
    };

    return (
      <Transition in={this.state.isMounted} timeout={300} onExited={this.onExited}>
        {state => (
          <Container style={{ ...overlayStyle[state] }}>
            <Popup style={{ ...popupStyle[state] }}>
              <a className="close_btn" href="#" onClick={() => this.handleClose()}>
                <Close />
              </a>
              <div className="about_content">
                <div className="about_left">
                  <p>
                    <strong>ICONSVG</strong> is a tool to simplify the process of finding and
                    generating common icons for your project.
                  </p>
                  <ol>
                    <li>Browse from a collection of basic and commonly-used interface icons</li>
                    <li>Modify some of its appearances including color, stroke, joins and ends</li>
                    <li>Download the icon, or copy the SVG code into your code editor</li>
                  </ol>

                  <p className="note">
                    Created by{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://twitter.com/gaddafirusli"
                    >
                      @gaddafirusli
                    </a>
                    <br />
                    Support by{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://buymeacoffee.com/gaddafirusli"
                    >
                      buying me a coffee
                    </a>{" "}
                    or{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.paypal.me/gaddafirusli/5"
                    >
                      donate
                    </a>
                  </p>
                </div>
                <div className="about_right">
                  <span>My other projects</span>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://shotsnapp.com/?ref=iconsvg"
                      >
                        <div>
                          <img src={ShotsnappIcon} alt="shotsnapp logo" />
                        </div>
                        <div>
                          <span>shotsnapp</span>
                          <em>Create beautiful device mockup images for your app or website</em>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://overframe.xyz/?ref=iconsvg"
                      >
                        <div>
                          <img src={OverframeIcon} alt="Overframe logo" />
                        </div>
                        <div>
                          <span>Overframe</span>
                          <em>Record your prototype & app with device frame overlay</em>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://heycoaster.com/?ref=iconsvg"
                      >
                        <div>
                          <img src={CoasterIcon} alt="coaster logo" />
                        </div>
                        <div>
                          <span>Coaster</span>
                          <em>Beautiful unofficial Unsplash app for MacOS and Windows</em>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Popup>
          </Container>
        )}
      </Transition>
    );
  }
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.25s ease-out;
  z-index: 4;
`;

const Popup = styled.div`
  position: absolute;
  width: 700px;
  background: #fbfafb;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  top: 15%;
  left: 50%;
  margin-left: -350px;
  overflow: hidden;
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  .about_content {
    display: flex;
    align-items: flex-end;
  }
  .about_left {
    font-size: 16px;
    line-height: 1.4;
    flex: 0.55;
    padding: 40px;
    background-color: #fff;
    border-right: 1px solid #eee;
    p {
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
      strong {
        font-size: 13px;
      }
      &.note {
        font-size: 14px;
        color: #444;
        line-height: 22px;
      }
    }
    a {
      color: #0094dd;
    }
    ol {
      margin-bottom: 20px;
      padding-left: 20px;
      li {
        margin-bottom: 5px;
      }
    }
  }
  .about_right {
    font-size: 15px;
    flex: 0.45;
    padding: 15px 15px 25px;
    & > span {
      font-size: 14px;
      color: #444;
    }
    ul {
      margin: 10px 0 0;
      padding-top: 10px;
      list-style: none;
      border-top: 1px solid #eee;
    }
    li {
      span {
        display: block;
        margin-bottom: 2px;
      }
      em {
        font-size: 13px;
        color: #444;
        font-style: normal;
      }
      img {
        width: 32px;
        margin-right: 10px;
      }
      a {
        text-decoration: none;
        padding: 12px;
        display: flex;
        color: #000;
        transition: all 0.15s ease-out;
        border-radius: 4px;
        &:hover {
          background-color: #f4f4f4;
          transition: all 0.15s ease-out;
          em {
            color: #333;
          }
        }
      }
    }
  }
  .close_btn {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;
