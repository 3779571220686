export function generateSVGCode(type, props) {
  if (type === "outline") {
    return generateSVGOutlineCode(props);
  } else {
    let code = generateSVGFillCode(props);
    let svgcode = `<svg xmlns="http://www.w3.org/2000/svg" width="${props.iconSize}" height="${
      props.iconSize
    }" viewBox="0 0 ${props.viewBox} ${props.viewBox}" fill="${
      props.fillColors.length > 1 ? "none" : props.fillColors
    }">${code}</svg>`;
    return svgcode;
  }
}

export function generateSVGOutlineCode({
  iconSize,
  viewBox,
  strokeColor,
  useCurrentColor,
  strokeWidth,
  linecap,
  linejoin,
  code
}) {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="${iconSize}" height="${iconSize}" viewBox="0 0 ${viewBox} ${viewBox}" fill="none" stroke="${
    useCurrentColor ? "currentColor" : strokeColor
  }" stroke-width="${strokeWidth}" stroke-linecap="${linecap}" stroke-linejoin="${linejoin}">${code}</svg>`;
}

export function generateJSXCode(type, props) {
  if (type === "outline") {
    let svg = generateSVGOutlineCode(props);
    let jsx = svg
      .replace(/(stroke-width)/g, "strokeWidth")
      .replace(/(stroke-linecap)/g, "strokeLinecap")
      .replace(/(stroke-linejoin)/g, "strokeLinejoin");
    return jsx;
  } else {
    let code = generateSVGFillCode(props);
    let jsx = `<svg xmlns="http://www.w3.org/2000/svg" width="${props.iconSize}" height="${
      props.iconSize
    }" viewBox="0 0 ${props.viewBox} ${props.viewBox}" fill="${
      props.fillColors.length > 1 ? "none" : props.fillColors
    }">${code}</svg>`;
    return jsx;
  }
}

export function generateSVGFillCode({ iconSize, viewBox, cornerRadius, fillColors, code }) {
  let svgcode = code;
  let base = svgcode.match(/<\s*(rect)[^>]*>/); // Get the first rect shape
  if (base) {
    let rect = base[0];
    let newrect = rect
      .replace(/(rx=)"[\d]*"/g, `rx="${cornerRadius}"`)
      .replace(/(ry=)"[\d]*"/g, `ry="${cornerRadius}"`); // replace radius with dynamic value
    let pattern = rect.replace(/(\/)/, `\\/`); // Add escape character
    let regex = new RegExp("(" + pattern + ")");
    svgcode = svgcode.replace(regex, newrect); // Replace first rect with updated rect
  }

  if (fillColors.length > 1) {
    svgcode = svgcode
      .replace(/(fill="#([0-9a-fA-F]{6})".*?)/g, "") // Remove existing fill
      .replace(/(fill="#([0-9a-fA-F]{3})".*?)/g, "") // Remove existing fill for short hex code
      .replace(/(class="base")/g, `fill="${fillColors[0]}"`) // Replace class with color
      .replace(/(class="one")/g, `fill="${fillColors[1]}"`) // Replace class with color
      .replace(/(class="two")/g, `fill="${fillColors[2]}"`) // Replace class with color
      .replace(/ +/g, " "); // Remove double spaces
  } else {
    svgcode = svgcode
      .replace(/(fill="#([0-9a-fA-F]{6})".*?)/g, "") // Remove existing fill
      .replace(/(fill="#([0-9a-fA-F]{3})".*?)/g, "") // Remove existing fill for short hex code
      .replace(/(class="base")/g, "") // Remove base class
      .replace(/ +/g, " "); // Remove double spaces
  }
  return svgcode;
}

export function generateReactCode(type, props) {
  let camelName = camalize(props.name);
  if (type === "outline") {
    return `import React from "react";const ${camelName} = ({size=${props.iconSize}, color="${
      props.useCurrentColor ? "currentColor" : props.strokeColor
    }"}) => (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 ${
      props.viewBox
    } ${props.viewBox}" fill="none" stroke={color} strokeWidth="${
      props.strokeWidth
    }" strokeLinecap="${props.linecap}" strokeLinejoin="${props.linejoin}">${
      props.code
    }</svg>);export default ${camelName};`;
  } else {
    let code = generateSVGFillCode(props);
    return `import React from "react"; const ${camelName} = ({size=${
      props.iconSize
    }}) => (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 ${
      props.viewBox
    } ${props.viewBox}" fill="${
      props.fillColors.length > 1 ? "none" : props.fillColors
    }">${code}</svg>); export default ${camelName};`;
  }
}

export function camalize(str) {
  return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr) {
    return chr.toUpperCase();
  });
}
