import React, { Component } from "react";
class HeadwayWidget extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.headwayapp.co/widget.js";
    document.head.appendChild(script);
    var config = {
      selector: "#headway-badge",
      account: "x95mox",
    };
    script.onload = function() {
      window.Headway.init(config);
    };
  }
  render() {
    return <span id="headway-badge" />;
  }
}
export default HeadwayWidget;
