import React, { Component } from "react";
import styled from "styled-components";
import dot from "./images/dot.svg";
import dotdark from "./images/dot-dark.svg";
import AppContext from "./AppContext.js";

export default class Canvas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkView: localStorage.getItem("darkView") === "true" ? true : false,
      zoom: 1
    };
  }
  toggleDarkView = darkView => {
    this.setState({ darkView });
    localStorage.setItem("darkView", darkView);
  };

  handleZoom = zoom => {
    this.setState({ zoom: zoom });
  };

  displayIcon = () => {
    return { __html: this.props.svg };
  };

  render() {
    let { darkView, zoom } = this.state;
    return (
      <AppContext.Consumer>
        {({ darkMode }) => (
          <>
            <Wrapper darkMode={darkMode} darkView={darkView} zoom={zoom}>
              <div
                className="icon"
                dangerouslySetInnerHTML={this.displayIcon()}
              />
              <div className="switcher">
                <button
                  className="light"
                  onClick={() => this.toggleDarkView(false)}
                />
                <button
                  className="dark"
                  onClick={() => this.toggleDarkView(true)}
                />
              </div>
            </Wrapper>
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 180px;
  margin-bottom: 10px;
  border: ${props => (props.darkMode ? "none" : "1px solid #ddd")};
  border-radius: 4px;
  position: relative;
  background-color: ${props => (props.darkView ? "#101115" : "#fff")};
  background-image: url(${props => (props.darkView ? dotdark : dot)});
  background-size: 12px 12px;
  background-position: center;

  color: ${props => (props.darkMode ? "#eee" : "#333")};

  .icon {
    /* transform: scale(2); */
    border: 1px solid
      ${props =>
        props.darkView ? "rgba(255, 255, 255, 0.15)" : "rgba(0, 0, 0, 0.1)"};
  }
  svg {
    display: block;
  }

  .switcher {
    position: absolute;
    top: 12px;
    right: 12px;
    button {
      width: 20px;
      height: 20px;
      border: 0;
      border-radius: 50%;
      margin-left: 6px;
      outline: none;
      cursor: pointer;
    }
    .light {
      background: #fff;
      border: ${props =>
        props.darkView ? "1px solid transparent" : "1px solid #ccc"};
    }
    .dark {
      background: #152430;
      border: ${props =>
        props.darkView ? "1px solid #ccc" : "1px solid transparent"};
    }
  }
`;
