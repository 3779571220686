import React, { useState } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
const ColorGroup = ({ label, colors, setMultiFillColors }) => {
  const [colorIndex, setColorIndex] = useState(null);

  const onChange = color => {
    setMultiFillColors(color.hex, colorIndex);
  };

  return (
    <Row>
      <span>{label}</span>
      <div className="colorrow">
        {colors.map((c, i) => {
          return (
            <div key={i} className="colorgroup">
              <button onClick={() => setColorIndex(i)}>
                <span
                  style={{
                    backgroundColor: c
                  }}
                />
              </button>
            </div>
          );
        })}
        {colorIndex !== null && (
          <div className="popover">
            <div className="cover" onClick={() => setColorIndex(null)} />
            <SketchPicker color={colors[colorIndex]} onChange={onChange} />
          </div>
        )}
      </div>
    </Row>
  );
};

export default ColorGroup;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  font-size: 15px;
  min-height: 38px;
  & > span {
    flex: 0.38;
  }
  & > div {
    flex: 0.62;
  }

  label {
    font-size: 13px;
    color: #222;
  }
  .colorrow {
    display: flex;
    position: relative;
  }
  .colorgroup {
    display: flex;
    flex: 0 0 38px;
    margin-right: 4px;
    button {
      background: #fff;
      border-radius: 4px;
      margin: 0;
      border: 1px solid #ddd;
      outline: none;
      padding: 4px;
      &:disabled {
        opacity: 0.5;
      }
    }
    span {
      width: 30px;
      height: 18px;
      border-radius: 2px;
      display: block;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
      position: relative;
      &.none {
        overflow: hidden;
        margin: 1px 0 0;
        &::before {
          position: absolute;
          content: "";
          width: 10px;
          height: 1px;
          background-color: red;
          transform: rotate(45deg);
          left: 5px;
          bottom: 9px;
        }
        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 1px;
          background-color: red;
          transform: rotate(-45deg);
          left: 5px;
          bottom: 9px;
        }
      }
    }
  }
  .popover {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 40px;
  }
  .cover {
    position: fixed;
    /* background: red; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
