import React from "react";
import styled from "styled-components";
import AppContext from "./AppContext.js";

class IconItem extends React.Component {
  createMarkup = () => {
    return { __html: this.props.code };
  };

  selectIcon = () => {
    let icon = {
      name: this.props.name,
      code: this.props.code
    };
    this.props.selectIcon(icon);
  };

  render = () => {
    let type = this.props.type;
    return (
      <AppContext.Consumer>
        {({ darkMode }) => (
          <>
            <Item
              darkMode={darkMode}
              active={this.props.active}
              size={this.props.size + 40}
              onClick={() => this.selectIcon()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={this.props.size}
                height={this.props.size}
                viewBox={"0 0 " + this.props.viewBox + " " + this.props.viewBox}
                fill={type === "fill" ? (darkMode ? "#eee" : "black") : "none"}
                stroke={
                  type === "outline" ? (darkMode ? "#eee" : "black") : null
                }
                strokeWidth={type === "outline" ? this.props.stroke : null}
                strokeLinecap={type === "outline" ? "round" : null}
                strokeLinejoin={type === "outline" ? "round" : null}
                dangerouslySetInnerHTML={this.createMarkup()}
              />
            </Item>
          </>
        )}
      </AppContext.Consumer>
    );
  };
}

export default IconItem;

const Item = styled.button`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: all ease-out 0.15s;
  box-shadow: ${props =>
    props.active ? "0 2px 4px rgba(0, 0, 0, 0.07)" : "none"};
  border: ${props => (props.active ? "2px solid" : "1px solid")};
  border-color: ${props =>
    props.active
      ? "#2692ee"
      : props.darkMode
      ? "rgba(255, 255, 255, 0.08)"
      : "rgba(0, 0, 0, 0.03)"};
  background-color: ${props =>
    props.active ? "rgba(38,146,238,0.12)" : "transparent"};
  &:hover {
    /* transform: scale(1.04); */
    transition: all ease-out 0.15s;
    border-color: ${props =>
      props.active
        ? "#2692ee"
        : props.darkMode
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(0, 0, 0, 0.1)"};
  }
  &:focus {
    /* transform: scale(1.04); */
    transition: all ease-out 0.15s;
    box-shadow: ${props => (props.active ? "transparent" : "#99d5f2")} 0px 0px
      0px 2px;
  }
`;
