import React from "react";
import styled from "styled-components";
import AppContext from "../AppContext.js";

const Segmented = ({ label, options = [], value, onChange }) => {
  return (
    <AppContext.Consumer>
      {({ darkMode }) => (
        <>
          <Row darkMode={darkMode}>
            <span>{label}</span>
            <div>
              <div className="buttons">
                {options.map((o, i) => {
                  return (
                    <button
                      key={i}
                      title="Butt"
                      className={value === o.name ? "active" : ""}
                      onClick={() => onChange(o.name)}
                    >
                      {/* <CapsButt /> */}
                      {o.icon}
                    </button>
                  );
                })}
              </div>
            </div>
          </Row>
        </>
      )}
    </AppContext.Consumer>
  );
};

export default Segmented;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  font-size: 15px;
  min-height: 42px;
  color: ${props => (props.darkMode ? "rgba(255,255,255,0.6)" : "#222")};
  & > span {
    flex: 0.38;
  }
  & > div {
    flex: 0.62;
  }
  label {
    font-size: 13px;
  }
  .buttons {
    border: ${props => (props.darkMode ? "none" : "1px solid #ddd")};
    border-radius: 4px;
    overflow: hidden;
    background: ${props => (props.darkMode ? "#2A2C34" : "#fff")};
    display: flex;
    button {
      border: 0;
      background: transparent;
      padding: 8px 20px 8px;
      outline: none;
      border-right: ${props =>
        props.darkMode ? "2px solid rgb(25, 26, 30)" : "1px solid #ddd"};
      color: ${props => (props.darkMode ? "rgba(255,255,255,0.4)" : "#888")};
      flex: 1;
      cursor: pointer;
      svg .base {
        fill: ${props => (props.darkMode ? "#fff" : "#eee")};
        opacity: ${props => (props.darkMode ? "0.08" : "0.8")};
      }
      &.active {
        color: #0194dd;
        svg .base {
          fill: #0194dd;
          opacity: 0.3;
        }
      }
      &:last-child {
        border: 0;
      }
    }
  }
`;
