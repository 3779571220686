import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as Close } from "./images/close.svg";
import { ReactComponent as Loader } from "./images/loading.svg";
import { ReactComponent as Info } from "./images/info.svg";
import Transition from "react-transition-group/Transition";

export default class License extends Component {
  state = {
    isMounted: false,
    key: "",
    isLoading: false,
    isError: false
  };

  componentDidMount = () => {
    this.setState({ isMounted: true });
  };

  handleClose = () => {
    this.setState({
      isMounted: false
    });
  };

  handleChange = e => {
    if (e.target.value === "") {
      this.setState({
        key: e.target.value,
        isError: false
      });
    } else {
      this.setState({
        key: e.target.value
      });
    }
  };

  onExited = () => {
    this.props.toggle();
  };

  onSubmit = e => {
    if (e.key === "Enter") {
      let key = e.target.value;
      let currentSet = this.props.currentSet;
      this.setState({ isLoading: true });
      this.props
        .verifyLicense(currentSet.id, currentSet.gumroad.id, key)
        .then(success => {
          if (!success) {
            this.setState({ isLoading: false, isError: true });
          }
        });
    }
  };

  render() {
    const overlayStyle = {
      entering: {
        background: "rgba(0, 0, 0, 0)"
      },
      entered: { background: "rgba(0, 0, 0, 0.3)" },
      exiting: {
        background: "rgba(0, 0, 0, 0)"
      }
    };

    const popupStyle = {
      entering: {
        opacity: 0,
        transform: "scale(0.9)"
      },
      entered: {
        opacity: 1,
        transform: "scale(1)"
      },
      exiting: {
        opacity: 0,
        transform: "scale(0.9)"
      }
    };

    return (
      <Transition
        in={this.state.isMounted}
        timeout={300}
        onExited={this.onExited}
      >
        {state => (
          <Overlay style={{ ...overlayStyle[state] }}>
            <Popup style={{ ...popupStyle[state] }}>
              <a
                className="close_btn"
                href="#"
                onClick={() => this.handleClose()}
              >
                <Close />
              </a>

              <>
                <div className="intro">
                  <span>{this.props.currentSet.name}</span>
                  <h3>Enter your {this.props.currentSet.name} access code</h3>
                  <p>
                    Your access code is the license key you received after you
                    made the payment on Gumroad. You can also get it from the
                    receipt in your email.
                  </p>
                </div>

                <Form>
                  <input
                    placeholder="e.g: 2024654C-8864420D-99DB1E69-8BA4D9F3"
                    value={this.state.key}
                    onChange={this.handleChange}
                    onKeyPress={this.onSubmit}
                    disabled={this.state.isLoading}
                  />
                  {this.state.isLoading && (
                    <div className="loading">
                      <Loader />
                    </div>
                  )}
                  {this.state.key !== "" &&
                    !this.state.isLoading &&
                    !this.state.isError && <span>Press Enter</span>}
                  {/* {this.state.isLoading && <span>Verifying...</span>} */}
                  {this.state.key !== "" && this.state.isError && (
                    <span className="error">Invalid key</span>
                  )}
                </Form>
              </>
            </Popup>
          </Overlay>
        )}
      </Transition>
    );
  }
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.25s ease-out;
  z-index: 4;
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Popup = styled.div`
  position: absolute;
  width: 620px;
  background: #fbfafb;
  border-radius: 6px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  top: 30%;
  left: 50%;
  margin-left: -310px;
  overflow: hidden;
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  padding: 30px;
  .close_btn {
    position: absolute;
    top: 19px;
    right: 15px;
  }
  .loading {
    top: 19px;
    position: absolute;
    right: 15px;
    color: #666;
    svg {
      animation: ${spin} 1.5s linear infinite;
    }
  }
  .intro {
    margin-bottom: 20px;
    span {
      text-transform: uppercase;
      letter-spacing: 0.8px;
      font-size: 13px;
      font-weight: 600;
      color: #888;
      display: block;
      margin-bottom: 16px;
    }
    h3 {
      font-weight: 500;
      margin-bottom: 8px;
    }
    p {
      color: #444;
    }
  }
`;

const Form = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  span {
    position: absolute;
    top: 21px;
    right: 20px;
    font-size: 15px;
    color: #666;
    &.error {
      color: red;
    }
  }
  input {
    padding: 20px 20px 20px 20px;
    width: 100%;
    border: none;
    font-size: 20px;
    background-color: #f2f2f2;
    color: #333;
    border-radius: 8px 8px;
    outline: none;
    &:focus {
      background-color: #f8f8f8;
      box-shadow: #99d5f2 0px 0px 0px 2px;
    }
    &:disabled {
      color: #888;
    }
  }
`;
