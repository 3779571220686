import React from "react";
import styled from "styled-components";
import Slider from "react-rangeslider";
import AppContext from "../AppContext.js";

const Range = ({ label, value, min, max, step, onChange }) => {
  return (
    <AppContext.Consumer>
      {({ darkMode }) => (
        <>
          <Row darkMode={darkMode}>
            <span>{label}</span>
            <div className="rangeview">
              <Slider
                min={min}
                max={max}
                step={step}
                tooltip={false}
                value={value}
                orientation="horizontal"
                onChange={onChange}
              />
              <span>
                {value}
                px
              </span>
            </div>
          </Row>
        </>
      )}
    </AppContext.Consumer>
  );
};

export default Range;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  font-size: 14px;
  min-height: 38px;
  color: ${props => (props.darkMode ? "rgba(255,255,255,0.6)" : "#222")};
  & > span {
    flex: 0.38;
  }
  & > div {
    flex: 0.62;
  }
  &.smaller {
    min-height: 10px;
    margin-bottom: 14px;
  }
  label {
    font-size: 13px;
    input {
      margin-right: 4px;
    }
    pre {
      display: inline-block;
      margin-left: 1px;
    }
  }
  .rangeview {
    display: flex;
    align-items: center;
    .rangeslider {
      flex: 1;
      background: ${props =>
        props.darkMode ? "rgba(255,255,255,0.15)" : "#dedede"};
    }
    span {
      font-size: 13px;
      text-align: right;
      width: 48px;
    }
  }
`;
