import Image1 from "../images/tritone/1.jpg";
import Image2 from "../images/tritone/2.jpg";
import Image3 from "../images/tritone/3.jpg";

const loader = {
  id: "ldr0001",
  name: "Loader",
  description:
    "Ut enim ad minim veniam, quis nostrud Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
  type: "fill",
  isPremium: false,
  images: [Image1, Image2, Image3],
  price: "$6",
  link: "http://gumroad.com",
  size: 32,
  fillColors: ["red"],
  viewBox: 48,
  cornerRadius: null,
  preCode: "",
  postCode: "",
  content: [
    {
      title: null,
      icons: [
        {
          name: "circular",
          code:
            '<g transform="translate(1 1)" stroke-width="2" stroke="red" fill="none" fill-rule="evenodd"><circle stroke-opacity=".5" cx="18" cy="18" r="18"/><path d="M36 18c0-9.94-8.06-18-18-18"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g>',
          tags: ["mail", "send", "paper", "flight"]
        }
      ]
    }
  ]
};

export default loader;
