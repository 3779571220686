import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
import feathers from "./data/feathers";
import tritone from "./data/tritone";
import loader from "./data/aloader";
import socials from "./data/socials";
import manualicons from "./data/manualicons";
import AppContext from "./AppContext.js";
import { ReactComponent as Lock } from "./images/lock.svg";
import { ReactComponent as Folder } from "./images/grid.svg";
import HeadwayWidget from "./HeadwayWidget";

const Menu = () => {
	let sets = [feathers, socials, tritone];
	return (
		<AppContext.Consumer>
			{({ currentSet, setCurrentSet, darkMode, ipc }) => (
				<>
					<Wrap darkMode={darkMode}>
						<div className="intro">
							<div className="title">
								<h1>ICONSVG</h1> <HeadwayWidget />
							</div>
							<p>Quick customizable icons for your projects</p>
						</div>
						<ul className="set">
							{sets.map((s, i) => {
								return (
									<li
										key={i}
										className={currentSet.name === s.name ? "active" : ""}
										onClick={() => setCurrentSet(s)}
									>
										{s.isPremium && !(ipc.indexOf(s.id) > -1) ? (
											<Lock />
										) : (
											<Folder />
										)}
										<span>{s.name}</span>
										{s.isPremium && !(ipc.indexOf(s.id) > -1)}
									</li>
								);
							})}
						</ul>
						<Footer />
					</Wrap>
				</>
			)}
		</AppContext.Consumer>
	);
};

export default Menu;

const Wrap = styled.div`
	width: 200px;
	box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.05);
	background: ${(props) => (props.darkMode ? "rgb(25, 26, 30)" : "#f7f7f7")};
	.intro {
		padding: 20px 14px;
		margin-bottom: 16px;
		.title {
			display: flex;
			align-items: center;
			margin-bottom: 8px;
		}
		h1 {
			font-size: 18px;
			color: ${(props) => (props.darkMode ? "#fff" : "#000")};
		}
		p {
			font-size: 15px;
			line-height: 1.5;
			color: ${(props) =>
				props.darkMode ? "rgba(255, 255, 255, 0.6);" : "rgba(0, 0, 0, 0.6)"};
		}
	}

	ul.set > li {
		margin: 1px 8px;
		padding: 10px 10px;
		cursor: pointer;
		border-radius: 4px;
		/* color: #444; */
		color: ${(props) =>
			props.darkMode ? "rgba(255, 255, 255, 0.9);" : "rgba(0, 0, 0, 0.8)"};
		display: flex;
		align-items: center;
		&.active {
			/* background: #666; */
			background: #0094dd;
			color: #fff;
			font-weight: 600;
			svg {
				g {
					stroke: white;
					rect {
						fill: rgba(255, 255, 255, 0.2);
					}
				}
			}
			em {
				background: rgba(255, 255, 255, 0.8);
				color: #0094dd;
			}
		}
		&:not(.active):hover {
			background: ${(props) =>
				props.darkMode ? "rgba(0, 0, 0, 0.3);" : "#eee"};
		}
		svg {
			margin-right: 10px;
			g rect {
				fill: rgba(0, 0, 0, 0.15);
			}
		}
		em {
			font-style: normal;
			background: #f7b500;
			margin-left: 8px;
			border-radius: 4px;
			letter-spacing: 1px;
			color: ${(props) => (props.darkMode ? "#333" : "#fff")};
			font-size: 10px;
			text-transform: uppercase;
			padding: 2px 4px;
			margin-top: 1px;
			font-weight: bold;
		}
	}
`;
