import React, { Component } from "react";
import styled from "styled-components";
import IconItem from "./IconItem.js";
import AppContext from "./AppContext.js";

export default class Browser extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {({
          selectIcon,
          selected,
          currentSet,
          darkMode,
          verifyLicense,
          ipc
        }) => (
          <>
            {currentSet.content.map(el => {
              return (
                <Section key={el.title} darkMode={darkMode}>
                  {el.title && <p>{el.title}</p>}
                  <Grid size={currentSet.size + 40 + 16}>
                    {el.icons.map(ic => {
                      return (
                        <IconItem
                          key={ic.name}
                          size={currentSet.size}
                          type={currentSet.type}
                          viewBox={currentSet.viewBox}
                          stroke={currentSet.type === "outline" ? 2 : 0}
                          code={
                            currentSet.preCode + ic.code + currentSet.postCode
                          }
                          name={ic.name}
                          active={selected ? selected.name === ic.name : false}
                          selectIcon={selectIcon}
                        />
                      );
                    })}
                  </Grid>
                </Section>
              );
            })}
            <Note darkMode={darkMode}>
              <p dangerouslySetInnerHTML={{ __html: currentSet.disclaimer }} />
            </Note>
          </>
        )}
      </AppContext.Consumer>
    );
  }
}

const Section = styled.div`
  margin: 0 auto 40px;
  max-width: 1000px;
  p {
    font-size: 14px;
    color: ${props =>
      props.darkMode ? "rgba(255, 255, 255, 0.7);" : "rgba(0, 0, 0, 0.6)"};
    margin: 0 8px 10px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: ${props =>
    props.size ? "repeat(auto-fill, " + props.size + "px)" : ""};
  justify-content: space-between;
`;

const Note = styled.div`
  border-top: ${props =>
    props.darkMode
      ? "1px solid rgba(255, 255, 255, 0.2);"
      : "1px solid rgba(0, 0, 0, 0.1)"};
  padding-top: 20px;
  margin-top: 20px;
  p {
    margin: 0 auto;
    max-width: 1000px;
    font-size: 15px;
    color: ${props =>
      props.darkMode ? "rgba(255, 255, 255, 0.5);" : "rgba(0, 0, 0, 0.6)"};
    line-height: 1.4;
  }
  a {
    color: ${props =>
      props.darkMode ? "rgba(255, 255, 255, 0.5);" : "rgba(0, 0, 0, 0.6)"};
  }
`;
