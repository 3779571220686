import React, { useState } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import AppContext from "../AppContext.js";

const Color = ({
  label,
  value,
  useCurrentColor,
  toggleCurrentColor,
  changeColor,
  hideCurrentColor = false
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const onInputChange = e => {
    let hex = e.target.value;
    changeColor(hex);
  };

  const handleColorPicker = color => {
    changeColor(color.hex);
  };
  return (
    <AppContext.Consumer>
      {({ darkMode }) => (
        <>
          <Row darkMode={darkMode}>
            <span>{label}</span>
            <div>
              <div className="colorrow">
                <div className="colorgroup">
                  <button
                    style={{
                      backgroundColor: value
                    }}
                    disabled={useCurrentColor}
                    onClick={() => setDisplayColorPicker(true)}
                  >
                    {/* <span
                     
                    /> */}
                  </button>
                </div>
                <div className="colorinput">
                  <input
                    type="text"
                    id="hexinput"
                    disabled={useCurrentColor}
                    value={value}
                    onChange={onInputChange}
                  />
                </div>

                {displayColorPicker ? (
                  <div className="popover">
                    <div
                      className="cover"
                      onClick={() => setDisplayColorPicker(false)}
                    />
                    <SketchPicker color={value} onChange={handleColorPicker} />
                  </div>
                ) : null}
              </div>
            </div>
          </Row>
          {!hideCurrentColor && (
            <SmallRow darkMode={darkMode}>
              <span />
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={useCurrentColor}
                    onChange={toggleCurrentColor}
                  />{" "}
                  Use <pre>currentColor</pre>
                </label>
              </div>
            </SmallRow>
          )}
        </>
      )}
    </AppContext.Consumer>
  );
};
export default Color;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  font-size: 15px;
  min-height: 40px;
  color: ${props => (props.darkMode ? "rgba(255,255,255,0.6)" : "#222")};
  & > span {
    flex: 0.38;
  }
  & > div {
    flex: 0.62;
  }

  label {
    font-size: 13px;
  }
  .colorrow {
    display: flex;
    position: relative;
    .colorinput {
      flex: 1;
      /* margin-left: 5px; */
      input {
        padding: 7px 10px;
        border-radius: 0 4px 4px 0;
        width: 100%;
        font-size: 13px;
        background: ${props => (props.darkMode ? "#2A2C34" : "#fff")};
        border: ${props =>
          props.darkMode ? "1px solid #2A2C34" : "1px solid #ddd"};
        color: ${props => (props.darkMode ? "#eee" : "#000")};
        border-left: none;
        outline: none;
        &:disabled {
          opacity: 0.5;
        }
        &:focus {
          border-color: ${props => (props.darkMode ? "#ffffff33" : "#bbb")};
        }
      }
    }
  }
  .colorgroup {
    display: flex;
    flex: 0 0 40px;
    button {
      background: ${props => (props.darkMode ? "#2A2C34" : "#fff")};
      border-radius: 4px 0 0 4px;
      margin: 0;
      border: none;
      outline: none;
      width: 40px;
      box-shadow: ${props =>
        props.darkMode ? "none" : "inset 0 0 0 1px rgba(0, 0, 0, 0.1)"};
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  .popover {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 40px;
    input {
      width: 100% !important;
    }
  }
  .cover {
    position: fixed;
    /* background: red; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const SmallRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  min-height: 10px;
  margin-bottom: 14px;
  color: ${props => (props.darkMode ? "#eee" : "#222")};

  & > span {
    flex: 0.38;
  }
  & > div {
    flex: 0.62;
  }
  input {
    margin-right: 4px;
  }
  pre {
    display: inline-block;
    margin-left: 1px;
  }
`;
